@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans&family=Roboto&family=Raleway:wght@100;400&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Roboto", sans-serif;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: scroll;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnSpaceBtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tabsIcon {
  font-size: 6vh;
}

.store-main-card {
  padding-top: 10px;
}

.store-details-card {
  display: flex;
  flex-direction: row;
  margin: 10px;
  border: none !important;
}

.gray-overlay .gray-overlay-sm {
  position: relative;
  border-radius: 8px;
  background-image: linear-gradient(
    transparent,
    transparent,
    rgb(0 0 0 / 80%) 99%
  );
  width: 100%;
}

.navbar {
  border-top: 1px solid #dee2e6 !important;
  background: white;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  padding-left: 10vh;
  padding-right: 10vh;
}

/* Style the links inside the navigation bar */
.navbar a {
  float: left;
  display: block;
  color: #989898;
  text-align: center;
  padding: 6px 16px;
  text-decoration: none;
  font-size: 1.2rem;
}

/* Change the color of links on hover */
.navbar a:hover {
  border-top: 2px solid #1187e4;
  color: #1187e4;
}

/* Add a color to the active/current link */
.navbar a.active {
  background-color: #4caf50;
  color: white;
}

.gray-overlay {
  height: 124px;
}

.category-text {
  margin-bottom: 0;
  position: absolute;
  bottom: 4px;
  white-space: pre-wrap;
  line-height: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.gray-overlay,
.gray-overlay-sm {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(
    transparent,
    transparent,
    rgb(0 0 0 / 80%) 99%
  );
  width: 100%;
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
  object-position: center;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-right: 10px;
}

.basic-details-card-thumb {
  align-items: center;
  justify-content: center;
}

.store-details-card-image {
  width: 13vh;
  height: 13vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}
.store-details-card-image-1 {
  width: 6vh;
  height: 6vh;
  border: 1px solid #ccc;
  border-radius: 4px;

  margin-top: -24px;
  margin-right: 10px;
}
.store-powered-by-card-image {
  width: 10vh;
}

.store-details-card-address {
  display: flex;
  flex-direction: column;
}

.basic-details-card-text > p {
  font-size: 1.2em;
  color: black;
  font-weight: bolder;
}

.thumbnail-container > .basic-details-card-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.thumbnail-container > h6 {
  color: black;
  font-size: 1.5em;
  text-decoration: none;
}

.btn-outline-primary {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #146eb4;
  border-color: #146eb4;
  font-size: 15px;
  font-weight: bolder;
  letter-spacing: 1px;
}

.addIcon {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.basic-details-card-button {
  margin: 1em;
}

@media (max-width: 767px) {
  .search-container {
    margin-top: 1em;
    margin-left: -2rem;
    margin-right: -1rem;
  }
}

@media (min-width: 768px) {
  .search-container {
    margin-top: 3em;
    margin-left: -2rem;
    margin-right: -3.5rem;
    padding-bottom: 2em;
  }
}
hr {
  border: 0;
  display: block;
  width: 100%;
  background-color: rgb(230, 230, 230);
  height: 1px;
}
.search-bar {
  padding-right: 45px;
}
.search-bar > .form-control {
  height: calc(1.5em + 0.85rem + 2px);
  border-radius: 5px;
  background: #f2f2f2;
  font-size: 1.5em;
  letter-spacing: 0.5px;
  border: 0px;
}

.search-button > svg {
  color: white;
  font-size: 12px;
  margin-left: -10px;
  margin-top: -8px;
  color: white;
  font-size: 24px;
  margin-left: -9px;
  margin-top: -8px;
}

.search-button {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: #117fd6;
  padding: 0.9em;
  font-size: 1.2em;
  margin-left: 0rem;
  border: 0px;
  position: absolute;
  top: -4px;
  right: 10px;

  margin: 5px;
  margin-right: 10px;
}
.search-button-container {
  padding: 0px;
}

.products-under-category-list > p {
  font-size: 2rem;

  font-weight: bolder;
  margin-bottom: 0rem;
}

.products-under-category-list {
  margin-top: 2rem;
}
.cart-checkout-button {
  padding-top: 4vh;
}
.cart-continue-button {
  width: 100%;

  padding: 1em;
  font-size: 1.8em;
  font-weight: bolder;
  border-radius: 0.8em;
}
.confirm-order-continue-button {
  width: 100%;
  padding: 0.8em;
  font-size: 1.6em;
  font-weight: bolder;
  border-radius: 0.8em;
}

.cart-mobile-final-total-container {
  display: none;
}
@media (max-width: 767px) {
  .category-card-image {
    width: 100%;

    border-radius: 1rem;
  }
  .cart-item-details {
    font-size: 10px;
  }
  .navbar {
    padding-left: 0vh;
    padding-right: 0vh;
  }
  .cart-checkout-button {
    position: fixed;
    background: white;
    z-index: 1000;
    width: 100%;
    left: 0;
    bottom: 6rem;
    padding: 2vh;
    border-top: 2px solid #efefef;
  }
  .cart-continue-button {
    margin-top: 0px;
    padding: 1vh;
  }
  .cart-mobile-final-total-container {
    display: initial;
  }
}

@media (min-width: 768px) {
  .category-card-image {
    width: 100%;
    height: 22vh;
    border-radius: 1rem;
  }
  .cart-item-details {
    font-size: 14px;
  }
}

.tabs-inner > .hydrated {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.overlay-text-card {
  margin-top: 10px;
}

.page-heading {
  margin-top: 1em;
  width: 100%;
}

.page-heading > h6 {
  padding: 1rem;
}

.product-info {
  margin-top: 1em;
}

.product-name {
  font-size: 2.2vh;
}
.product-name > h4 {
  font-size: 1.6em;
  margin-top: 0.5em;
  font-weight: bold;
}
.product-name > p {
  font-size: 0.8em;
  color: #888;
}
.product-price > h3 {
  margin-top: 20px;
  font-size: 1.8em;
  font-weight: bold;
}

.cart-item-details > .quantity {
  font-size: 12px;
}

.cart-item-details > .price {
  font-weight: bolder;
}

.quantity-button {
  display: flex;
  flex-direction: column;
}

.quantity-button > div > span {
  margin: 1em;
  font-size: 1.2em;
  font-weight: bolder;
}

.cart-details {
  display: flex;
  flex-direction: column;
  background: #efefef;
  border-radius: 1em;
  padding: 0.75em;
  font-size: 1.5em;
  margin-top: 2em;
}

.cart-details > .table tr {
  border: none;
}

.cart-details > .table > .total {
  font-weight: bolder;
}

.confirm-order-cart-details {
  display: flex;
  flex-direction: column;
  background: #efefef;
  border-radius: 1em;
  padding: 0.75em;
  font-size: 1em;
  margin-top: 2em;
}
.confirm-order-cart-details > .table tr {
  border: none;
}

.confirm-order-cart-details > .table > .total {
  font-weight: bolder;
}

.checkout-field-form > div {
  width: 96%;
}

.account-main-details {
  padding: 1vh;
  margin-top: 1vh;
}

.product-details {
  padding: 0em;
}

.product-carousel-image {
  object-fit: contain;
  background-color: rgb(255, 255, 255);
  max-width: 100%;
  height: 75vh;
  width: 75vh;
  border-radius: 30px;
}

.product-info > div {
  width: 100%;
}

.buttons-add-buynow > button {
  font-size: 15px;
  font-weight: 500;
}

.buttons-add-buynow {
  margin-top: 2em;
}

.orders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-lhs > p {
  font-size: 1.5rem;
  color: purple;
}

.order-lhs > div > img {
  width: 14rem;
  border: 1px solid #d3d3d3;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.order-lhs {
  display: flex;
  flex-direction: row;
}

.order-lhs > .order-item-details {
  margin-left: 1rem;
  font-size: 13px;
}

.order-rhs > .price {
  font-size: 13px;
  font-weight: bolder;
}

.order-rhs > .date {
  font-size: 13px;
}

.order-store-name {
  margin-top: 0.5rem;
  font-size: 2.2vh;
  font-weight: bold;
}

.checkout-field-form {
  width: 100%;
  margin: 1rem !important;
}

.checkout-field-form .compact {
  width: 100% !important;
}

.checkout-field-form > label {
  font-size: 1.8em;
  padding-bottom: 1em;
}

.checkout-field-form input {
  font-size: 20px !important;
  padding: 12px 0 7px;
}
.checkout-section p {
  font-size: 1.5em;
  font-weight: 500;
  margin-top: 1em;
}

.checkout-section button {
  background: #8b008b;
  padding: 1.1em;
  border-radius: 0.5em;
  font-size: 1.7em;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 3em;
  width: 100%;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  width: 14vh;
  font-size: 15px;
  font-weight: bolder;
  float: right;
  border: 0px;
}
.quantity__minus,
.quantity__plus {
  outline: none !important;
  display: block;
  width: 33%;
  height: 5vh;
  margin: 0;
  background: #117fd6;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  color: white;
  font-weight: bold;
  font-size: 3vh;
  border: 0px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #117fd6;
  color: #fff;
}
.quantity__minus {
  border-radius: 3px 0 0 3px;
}
.quantity__plus {
  border-radius: 0 3px 3px 0;
}
.quantity__input {
  width: 33%;
  height: 5vh;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 0px solid #dee0ee;
  border-bottom: 0px solid #dee0ee;
  border-left: 0px solid #dee0ee;
  border-right: 0px solid #dee0ee;
  background: #1380d61f;
  color: #0e548c;
  outline: none;
  font-weight: bold;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #fff;
}

.btn-add {
  float: right;
  height: 5vh;
  border-radius: 10px;
}
.product-container {
  max-width: 1400px;
  padding-left: 5vh;
  padding-right: 5vh;
  margin-left: auto;
  margin-right: auto;
}
.variantHeading {
  font-size: 2.5vh;
  font-weight: bold;
}
.optionSelectInstruction {
  font-size: 2.5vh;
  margin-left: 5px;
  font-weight: 200;
}

.variantContainer {
  padding: 4vh;
}
.variantOptionHeading {
  padding: 1.2vh;
  font-size: 2.2vh;
  border: 1px solid #d0c5c5;
  margin: 10px;
  border-radius: 50px;
}
.variantOptionHeadingSelected {
  padding: 1.2vh;
  font-size: 2.2vh;
  border: 1px solid #8a0ee8;
  margin: 10px;
  border-radius: 50px;
  background: #8a0ee8;
  color: white;
  font-weight: bold;
}
.variantOptionPrice {
  font-size: 2.2vh;
  font-weight: bold;
  float: right;
}
.variantOptionName {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .product-thumbnail-image {
    border-radius: 1.5rem;
    border: 1px solid #e6e6e6;
    height: 10rem;
    width: 15rem;
    object-fit: contain;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .product-thumbnail-image {
    border-radius: 1.5rem;
    border: 1px solid #e6e6e6;
    height: 20rem;
    width: 100%;
    object-fit: contain;
  }
}
.product-thumbnail-image {
  border-radius: 1.5rem;
  border: 1px solid #e6e6e6;
  height: 20rem;
  width: 100%;
  object-fit: contain;
}

.fixedTop {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: match-parent;
  background: white;
  z-index: 1030;
}

.see-all-button {
  background: transparent;
  color: #3f51b5;
  font-weight: bolder;
  border: none;
  font-size: 1.5rem;
  text-align: right;
  width: 100%;
  padding: 0px;
}
.see-all-button:hover {
  background: transparent;
  border: none;
  color: #3f51b5;
}

.category-heading {
  font-size: 1.6rem;
  font-weight: bolder;
  margin-top: 1rem;
}

.categories-slider {
  margin-top: 1rem;
}
.productHeaderIcon {
  font-size: 3vh !important;
  display: inline-block;
}
.product-page-heading {
  margin-top: 1.5em;
  width: 100%;
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
}
.product-page-heading-title {
  font-size: 2.4vh;
  font-weight: bold;
  display: inline;
  vertical-align: top;
  margin-left: 0.6em;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}
.product-button-container {
  position: sticky;
  width: 100%;
  left: 0;
  background: white;
  bottom: 10px;
  margin-top: 20px;
}
.changeQuantity {
  font-size: 4vh;
  font-weight: bold;
  margin-bottom: 5px;
}
.discountCircle {
  padding-left: 5px;
  padding-right: 5px;
  background: #e91e63;
  margin-left: 10px;
  padding-top: 10px;
  color: white;
  padding-bottom: 6px;
  font-size: 14px;
  margin-top: 5px;
}
.discountCircleFront {
  padding-left: 5px;
  padding-right: 5px;
  background: #e91e63;
  margin-left: 10px;
  padding-top: 10px;
  color: white;
  padding-bottom: 6px;
  font-size: 1.6vh;
  float: right;
}
.single-order-container {
  border-bottom: 2px solid #efefef;

  padding-bottom: 2vh;
}
@media (max-width: 540px) {
  .product-thumbnail-image {
    border-radius: 1.5rem;
    border: 1px solid #e6e6e6;
    height: 10rem;
    width: 10rem;
    object-fit: contain;
  }
  .single-order-container {
    border-bottom: 3vh solid #efefef;
  }
  .product-container {
    padding-left: 0vh;
    padding-right: 0vh;
  }
  .product-page-heading {
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-carousel-image {
    height: 100vw;
    width: 100vw;
    border-radius: 0px;
  }
  .btn-add {
    float: right;
    height: 5vh;
    border-radius: 0px;
  }
  .quantity__minus,
  .quantity__plus,
  .quantity__input {
    height: 5vh;
  }
  .product-button-container {
    position: fixed;
    width: 100%;
    left: 0;
    background: #ffffff;
    z-index: 1000;
    bottom: 0;
    padding: 20px 10px 20px 10px;
  }
}
.otp-banner {
  background: #117fd6;
  color: white;
  padding: 3rem;
  font-size: 1.8rem;
  letter-spacing: 1px;
  word-spacing: 1px;
}

.otp-banner > div {
  margin-left: 2.2rem;
}

.otp-banner h6 {
  font-weight: bolder;
  font-size: 2rem;
}

.input-otp {
  margin-top: 5rem;
}

.input-otp > .form-control > label {
  margin-top: -2px;
  font-size: 2rem;
  word-spacing: 1px;
  letter-spacing: 2px;
}

.input-otp > .form-control > div > input {
  font-size: 2.5rem;
  margin-top: 0.8rem;
}

.input-otp > .form-control > div {
  width: 100%;
}

/*Hide arrows from otp number input */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.send-otp-button button {
  background: #117fd6;
  border-radius: 1rem;
  font-size: 1.8rem;
  width: 140px;
  margin-top: 5rem;
  height: 64px;
  color: white;
  font-weight: bolder;
  word-spacing: 2px;
  letter-spacing: 2px;
}

.re-send-otp-button button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  width: 124px;
  color: #117fd6;
  font-weight: bolder;
  letter-spacing: 2px;
}

.cart-add-gap-in-mobile {
  display: none;
}
.cart-coupon-area-mobile {
  display: initial;
}

@media (min-width: 768px) {
  .cart-item-image > img {
    border-radius: 1rem;
    width: 20vh;
    height: 20vh;
    border: 1px solid #cdcdcd;
  }
}
.addressFormContainer {
  box-shadow: 0px 0px 10px #ccc;
}
@media (max-width: 767px) {
  .addressFormContainer {
    box-shadow: 0px 0px 10px #ccc;
  }
  .confirm-order-cart-details {
    background: white;
  }

  .confirm-order-cart-details td {
    border-top: 0;
  }

  .cart-details {
    background: white;
    padding-top: 15vh;
  }
  .cart-details td {
    border-top: 0;
  }

  .cart-coupon-area-mobile {
    display: inline-block;
    background: #fff;
    width: 100%;
    left: 0;
    border-top: 14px solid #efefef;
    border-bottom: 14px solid #efefef;
    position: absolute;
    padding-left: 4vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .cart-item-image > img {
    border-radius: 1rem;
    width: 12vh;
    height: 12vh;
    border: 1px solid #cdcdcd;
  }
}

.product-info > div > div > div {
  margin-top: 0rem;
}

.container-cart-empty {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}
*:before,
*:after {
  display: block;
  position: absolute;
  content: "";
}
.cart {
  margin-top: 10vh;
}
.stand {
  width: 20px;
  height: 150px;
  background: black;
  transform-origin: 0% 0%;
  transform: rotate(-20deg);
  position: absolute;
  z-index: 3;
}
.stand:before {
  width: 20px;
  height: 100px;
  background: black;
  margin-top: 150px;
  transform-origin: 100% 0%;
  transform: rotatez(50deg);
  border-radius: 0 0 60% 20%;
}
.stand:after {
  width: 14px;
  height: 55px;
  background: black;
  margin: 150px 6px;
  border-radius: 0 0 60% 20%;
}
.skate {
  position: absolute;
  height: 25px;
  width: 200px;
  background: black;
  border-radius: 50% 5% 55% 5%;
  z-index: 2;
  margin: 188px 15px;
}
.wheels {
  position: absolute;
  z-index: 2;
  width: 220px;
  height: 50px;
  margin-top: 180px;
}
.wheel {
  border-radius: 50%;
  background: white;
  border: 5px solid grey;
}
.wheel:nth-child(1) {
  width: 30px;
  height: 30px;
  margin: 10px -8px;
}
.wheel:nth-child(2) {
  width: 20px;
  height: 20px;
  margin: -45px 185px;
}
.wheel:nth-child(2):before {
  width: 35px;
  height: 25px;
  border-top: 6px solid white;
  margin: -11px -8px;
  border-radius: 50%;
}
.basket {
  width: 190px;
  height: 120px;
  border-radius: 0 100px 50px 100px/0 30px 70px 100px;
  background: transparent;
  position: absolute;
  margin-left: 20px;
  border: 4px solid black;
  border-bottom-width: 10px;
}
.basket:before {
  width: 250px;
  height: 20px;
  border-radius: 20px 30% 60% 20px;
  background: black;
  margin: -18px -50px;
  transform-origin: 0% 50%;
  transform: rotate(5deg);
  z-index: 5;
}
.rec-v {
  width: 20px;
  height: 100px;
  border-radius: 10px;
  border: 4px solid white;
  transform-origin: 50% 0%;
  transform: rotatez(-10deg);
  position: absolute;
}
.rec-v:nth-child(1) {
  margin: 5px 10px;
}
.rec-v:nth-child(2) {
  margin: 3px 55px;
  transform: rotatez(-5deg) scaley(1.12);
}
.rec-v:nth-child(3) {
  margin: 7px 105px;
  transform: rotatez(0deg) scaley(1.1);
}
.rec-v:nth-child(4) {
  margin: 4px 155px;
  transform: rotatez(5deg) scaley(1.1);
}
.rec-h {
  width: 180px;
  height: 25px;
  border-radius: 15px;
  border: 4px solid black;
  transform-origin: 0% 50%;
  transform: rotate(5deg);
  margin: 15px 5px;
}
.rec-h2 {
  transform: rotate(5deg) scalex(0.9);
  margin: 15px;
}
.router-icon {
  font-size: 24px !important;
}

.m-3 p {
  font-size: 18px;
  font-weight: 500;
}
.margin-bottom-150px {
  margin-bottom: 150px;
}
.buttonProductOptionBack {
  background: #fff;
  color: #222;
  border-color: #fff;
  margin-bottom: 20px;
  width: fit-content;
  padding: 4px;
  cursor: pointer;
}
.buttonProductOptionBack:hover {
  background: #7434eb;
  color: #fff;
  border-color: #7434eb;
}
.buttonProductOptionBackText {
  font-size: 2vh;
  margin-left: 1px;
  margin-right: 1px;
  vertical-align: -webkit-baseline-middle;
}
.buttonMultipleOptionDrawer {
  display: inline-block;
  font-size: 2.4vh;
  margin: 10px;
}
.multipleOptionDrawerText {
  font-size: 2.4vh;
  padding: 10px;
  font-weight: bold;
}
a {
  text-decoration: none !important;
}
.categoriesSliderHeading {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 14px;
}
.storePoweredByCard {
  margin: 10px;
  text-align: center;
  width: 100%;
}
.storePoweredBy {
  font-size: 1rem;
  font-weight: bold;
  align-content: center;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  width: 100%;
}

.input-otp .form-control {
  height: 75px !important;
}

.cartCircle {
  background: #117fd6;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 100px;
  text-align: center;
  min-width: 22px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  position: absolute;
  margin-left: 3vh;
  margin-top: 1vh;
}
.cartText {
  font-size: 1rem;
  margin-top: 2px;
  font-weight: bold;
}
.noProductsFound {
  font-size: 3vh;
  font-weight: bold;
  text-align: center;
  color: #aaa;
}
.cart-product-name {
  font-weight: bolder;
  font-size: 1.4rem;
  color: rgb(34, 34, 34);
  margin-bottom: 0px;
}
.cart-product-price {
  font-weight: bold;
  font-size: 1.8rem;
  margin-top: 0.2rem;
  color: rgb(34, 34, 34);
}
.cart-product-price-rs-sign {
  font-size: 2rem;
  color: #0e0e0e;
  margin-right: 2px;
}
.cart-product-variant {
  font-size: 1.4rem;
  font-weight: 400;
  color: #777;
}
.cart-product-count {
  color: white;
  background: rgb(17, 127, 214);
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}
.cart-page-heading {
  margin-top: 1em;
  width: 100%;
  padding-top: 1vh;
  padding-left: 2vh;
  margin-bottom: 4vh;
  color: #545454;
  border-radius: 21px;
}
.cart-page-heading h5 {
  font-size: 2.5vh;
  font-weight: bold;
}
.cart-mobile-final-total {
  font-size: 3.8vh;
  font-weight: bold;
}

.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 172px !important;
}

.MuiAlert-message {
  padding: 14px 6px;
  font-size: 13px;
}
.MuiAlert-icon {
  display: flex;
  opacity: 0.9;
  padding: 12px 0;
  font-size: 22px;
  margin-right: 12px;
}
.cart-input-coupon-container {
  padding: 2vh;
}
.cart-input-coupon-container .form-control {
  height: 12vh;
}
.cart-input-coupon-container input {
  font-size: 3vh;
}
.cart-input-coupon-container label {
  font-size: 3vh;
}
.cart-coupon-text-mobile {
  font-weight: bold;
  font-size: 2vh;
  margin-top: 1vh;
  cursor: pointer;
}
.cart-coupon-icon-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  border-radius: 100%;
  border: 1px solid #efefef;
  background: #efefef;
  color: #777;
  font-weight: bold;
  font-size: 2rem;
  height: 3.5rem;
  width: 3.5rem;
  text-align: center;
}
.cart-coupon-chevron-right-mobile {
  float: right;
  font-size: 4vh !important;
  color: #ababab;
  margin-right: 3vh;
  margin-top: 0.5vh;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .cart-coupon-chevron-right-mobile {
    margin-right: 0px;
  }
}
.cart-empty-heading {
  font-size: 2vh;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}
.cart-empty-detail {
  font-size: 1.8vh;
  margin-top: 5px;
  text-align: center;
  color: #adadad;
}
.cart-empty-back-button {
  margin-top: 5vh;
  font-size: 1.8vh;
  font-weight: bold;
  letter-spacing: 1px;
  width: 100%;
  padding: 10px;
}

.single-order-id {
  font-weight: bold;
  font-size: 2.2vh;
}
.single-order-date {
  font-size: 1.5vh;
  color: #aaa;
}
.single-order-item-n-total {
  margin-top: 3vh;
  font-weight: bold;
}
.single-order-products {
  font-size: 1.8vh;
  color: #888;
  text-align: justify;
}

.single-order-button-details {
  width: 100%;
  padding: 6px;
  font-weight: bold;
  font-size: 1.5vh;
  letter-spacing: 1px;
  float: right;
}
.single-order-status {
  text-align: center;
  color: #555;
  font-weight: bold;
  font-size: 2vh;
  padding-bottom: 6vh;
  padding: 4px;
  background: #efefef;
  border-radius: 3px;
  margin-bottom: 6vh;
}
.single-order-status-circle {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50px;
}
.order-status-1 {
  background: #ff9800;
}
.account-page-heading {
  font-size: 2vh;
  font-weight: bold;
  margin-top: 1.5vh;
}
.account-page-logout-heading {
  display: inline;
  vertical-align: text-top;
}
.account-page-logout-button {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-right: 5px;
}
.payment-mode svg {
  width: 2em;
  height: 2.5em;
}
.order-confirm-text {
  font-weight: bolder;
  color: rgb(34, 34, 34);
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .cart-confirm-section {
    height: 40vh;
    overflow-y: scroll;
  }
}
@media (min-width: 768px) {
  .cart-confirm-section {
    height: 52vh;
    overflow-y: scroll;
  }
}
.checkout-save-address-button {
  padding: 10px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  float: right;
}
@media (max-width: 767px) {
  .checkout-save-address-button-container {
    padding-top: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0px;
    z-index: 1000;
    background: white;

    text-align: right;
  }
  .checkout-save-address-button {
    width: 100%;
    margin: 0px;
    padding: 20px;
    font-size: 2rem;
  }
  .search-bar {
    padding-right: 5px;
  }
  .search-button {
    right: 0;
  }
  .fixed-bottom {
    padding: 2vh;
    position: fixed;
    right: 0;
    bottom: 82px;
    left: 0;
    z-index: 1030;
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 20px;
  }
  .pay-now-button {
    display: none;
  }
}
.confirm-address {
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .fixed-bottom {
    padding: 3vh;
    position: fixed;
    right: 0;
    bottom: 82px;
    left: 270px;
    z-index: 1030;
    background: whitesmoke;
    border: 1px solid lightgray;
    width: 70%;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .pay-now-button {
    display: none;
  }
}
@media (min-width: 1200px) {
  .fixed-bottom {
    padding: 3vh;
    position: fixed;
    right: 0;
    bottom: 89px;
    left: 0px;
    z-index: 1030;
    background: whitesmoke;
    border: 1px solid lightgray;
    width: 100%;
    border-radius: 20px;
    font-size: 25px;
  }
  .cart-confirm-section {
    height: 38vh;
    overflow-y: scroll;
  }
  .payment-mode {
    padding: 6px !important;
  }
  .pay-now-button {
    border-radius: 15px;
    width: 100%;
    padding: 14px;
    font-size: 31px;
    word-spacing: 3px;
    letter-spacing: 6px;
    margin-top: 20px;
  }
  .confirm-order-continue-button {
    display: none;
  }
}

@media (min-width: 992px) {
  .fixed-bottom {
    padding-left: 3vh;
    position: fixed;
    right: 0;
    left: 200px;
    z-index: 1030;
    background: whitesmoke;
    border: 1px solid lightgray;
    width: 70%;
    border-radius: 20px;
    font-size: 20px;
  }
  .cart-confirm-section {
    height: 38vh;
    overflow-y: scroll;
  }
  .payment-mode {
    padding: 6px !important;
  }
  .pay-now-button {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    word-spacing: 3px;
    letter-spacing: 6px;
    margin-top: 30px;
  }
  .confirm-order-continue-button {
    display: none;
  }
}

.success-container {
  max-width: 380px;
  margin: 30px auto;
  overflow: hidden;
}
.success-page {
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden;
  background-color: #efefef;
}
.printer-top {
  z-index: 1;
  border: 6px solid #666666;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #333333;
}

.printer-bottom {
  z-index: 0;
  border: 6px solid #666666;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #333333;
}

.paper-container {
  position: relative;
  overflow: hidden;
  height: 467px;
}

.paper {
  background: #ffffff;
  height: 447px;
  position: absolute;
  z-index: 2;
  margin: 0 12px;
  margin-top: -12px;
  animation-iteration-count: 1;
  animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
  -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
}

.main-contents {
  margin: 0 12px;
  padding: 24px;
}

.jagged-edge {
  position: relative;
  height: 20px;
  width: 100%;
  margin-top: -1px;
}

.jagged-edge:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%
    );
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.success-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #359d00;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}
.failed-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #761212;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}
.pending-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #d89307;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}

.success-title {
  font-size: 22px;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.order-details {
  text-align: center;
  color: #333;
  font-weight: bold;
}
.order-details .order-number-label {
  font-size: 18px;
  margin-bottom: 8px;
}
.order-details .order-number {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 48px;
  font-size: 22px;
  padding: 8px 0;
  margin-bottom: 24px;
}

.order-footer {
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #999;
}

@keyframes print {
  0% {
    transform: translateY(-90%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes print {
  0% {
    -webkit-transform: translateY(-90%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@-moz-keyframes print {
  0% {
    -moz-transform: translateY(-90%);
  }
  100% {
    -moz-transform: translateY(0%);
  }
}
@-ms-keyframes print {
  0% {
    -ms-transform: translateY(-90%);
  }
  100% {
    -ms-transform: translateY(0%);
  }
}
@keyframes marker-ring {
  0% {
    height: 0px;
    width: 0px;
    border: 0px solid #111;
  }

  100% {
    height: 80px;
    width: 80px;
    border: 2px solid #efefef;
  }
}
.marker-ring-animation {
  animation-name: marker-ring;
  animation-duration: 1.8s;
  animation-delay: 1s;
  background: #bbb5b54f;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.error-message {
  margin-left: 5px;
  color: red;
  font-weight: bold;
}
.payment-mode-online.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #007bff !important;
}
.payment-mode-cash.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #009688 !important;
}
.payment-mode-delivery.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #007bff !important;
}
.payment-mode-pickup.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #009688 !important;
}
.btn-fab {
  position: fixed;
  width: 60px;
  height: auto;
  right: 10px;
  bottom: 70px;
}
.website-container {
  all: unset;
}
.banner-carousel-image {
  width: 100%;
  border-radius: 10px;
}
.banner .carousel-inner {
  border-radius: 10px;
}
.lobecho-branding {
  padding: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(#673ab7, #5d0af3);

  width: 100%;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.freeDeliveryDiv {
  height: 3em;
  width: 100%;
  background: #4c0dbd;
  margin-top: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #ccc;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 0.8em;
  font-size: 1.2em;
  letter-spacing: 0.1em;
}

.details-card .product-thumbnail-image {
  backdrop-filter: blur(20px);
}
.product-thumbnail-image-container {
  position: relative;
}
.product-image-backdrop {
  display: initial;
  width: 10rem;
  height: 10rem;
  z-index: -10;
  left: 0;
  top: 0;
  position: absolute;
}
.product-image-backdrop-image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.product-details-card .product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 575px) {
  .details-card {
    box-shadow: 10px 10px 20px #efefef;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 20px;
  }
  .details-card hr {
    display: none;
  }
  .details-card .product-details-card {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .details-card .product-thumbnail-image {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    backdrop-filter: blur(20px);
  }
  .product-thumbnail-image-container {
    position: relative;
  }
  .product-image-backdrop {
    display: initial;
    width: 100%;
    height: 100%;
    z-index: -10;
    left: 0;
    position: absolute;
  }
  .product-image-backdrop-image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .product-name {
    min-height: 4.2rem;
  }
}
.global-label-container {
  display: inline-block;
  padding: 4px;
  padding-left: 0px;
}
.global-label {
  white-space: nowrap;
  font-size: 1.2em;
  border-radius: 5px;
  padding: 4px;
  font-weight: bold;
}
.global-label-bg-blue {
  color: white;
  background: #0062cc;
}
.global-label-bg-gray {
  color: white;
  background: #adacac;
}
