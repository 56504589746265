.container {
  max-width: var(--theme-max-width);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.stickToTop {
  position: sticky;
  z-index: 12;
  top: 0;
  background: white;
}
